import React from "react";
import styled, { keyframes } from "styled-components";

const spin = keyframes`
  0% { transform: rotate(0deg); }
  100% { transform: rotate(360deg); }
`;

const Spinner = styled.div`
  width: 30px;
  height: 30px;
  border: 4px solid rgba(0, 0, 0, 0.1);
  border-top: 4px solid ${(props) => props.theme.secondaryColor};
  border-radius: 50%;
  animation: ${spin} 1s linear infinite;
`;

const Background = styled.div`
  width: 40px;
  height: 40px;
  border-radius: 50%;
  box-shadow: rgba(0, 0, 0, 0.24) 0px 3px 8px;
  display: flex;
  align-items: center;
  justify-content: center;
  position: absolute;
  left: 50%;
  top: 50%;
  transform: translate(-50%, -50%);
  background-color: white;
`;

const Content = styled.div`
  position: fixed;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  /* background-color: rgba(255, 255, 255, 0.3); */
  /* backdrop-filter: blur(1px); */
  z-index: 999;
`;

const LoadingSpinner = () => {
  return (
    <Content>
      <Background>
        <Spinner />
      </Background>
    </Content>
  );
};

export default LoadingSpinner;
