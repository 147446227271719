const theme = {
  primaryColor: "black",
  secondaryColor: "#BE000D",
  darkerSecondaryColor: "#8E000A",
  backgroundColor: "#ffffff",
  textColor: "white",
  accentColor: "#e74c3c",
};

export default theme;
