import axios from "axios";
import BASE_URL from "./baseUrl";
import { toast } from "react-toastify";
import store from "../store/store";

// Function to get the token from localStorage
const getToken = () => {
  const token = store.getState().auth.token;
  return token ?? null;
};

// Function to refresh the access token using the refresh token
async function refreshAccessToken() {
  try {
    await axios.post(
      `${BASE_URL}/user/refresh-token`,
      {},
      {
        withCredentials: true, // Ensures that the refresh token stored in cookies is sent
      }
    );

    return true;
  } catch (error) {
    console.error("Error refreshing access token:", error);
    return null;
  }
}

const instance = axios.create({
  baseURL: BASE_URL,
  withCredentials: true,
});

// Axios interceptor to set Authorization header for every request
instance.interceptors.request.use(
  (config) => {
    const token = getToken();
    if (token) {
      config.headers.Authorization = `Bearer ${token}`;
    }
    return config;
  },
  (error) => {
    return Promise.reject(error);
  }
);

// Axios response interceptor to handle token expiration and retry the request
instance.interceptors.response.use(
  (response) => response,
  async (error) => {
    try {
      const originalRequest = error.config;

      // Check if error is due to token expiration
      if (
        error.response.status === 401 &&
        error.response.data.error === "Token expired" &&
        !originalRequest._retry // Avoid infinite loop
      ) {
        originalRequest._retry = true; // Mark the request as retried

        // Attempt to refresh the access token
        const newToken = await refreshAccessToken();

        if (newToken) {
          // Retry the original request with the new token
          return instance(originalRequest);
        } else {
          toast.error("Session expired. Please login again.", {
            position: toast.POSITION.TOP_RIGHT,
          });
          // Display the custom alert instead of window.alert
          localStorage.clear();
          setTimeout(() => {
            // window.alert("Session expired. Please login again.");
            window.location.href = "/auth";
          }, 2000);
        }
      }

      return Promise.reject(error);
    } catch (error) {
      console.log(`Error`, error);
    }
  }
);

export default instance;
