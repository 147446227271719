import { useEffect, useState, lazy, Suspense } from "react";
import { BrowserRouter as Router, Routes, Route } from "react-router-dom";
import { Helmet } from "react-helmet-async";
// import Temporary from "./components/Temporary";
import ManageCategories from "./page/ManageCategories";
import LoadingSpinner from "./components/Spinner";

const HomePage = lazy(() => import("./page/HomePage"));
const AuthenticationPage = lazy(() => import("./page/AuthenticationPage"));
const ProductPage = lazy(() => import("./page/ProductPage"));
const CartPage = lazy(() => import("./page/CartPage"));
const AddressPage = lazy(() => import("./page/AddressPage"));
const CheckoutPage = lazy(() => import("./page/CheckoutPage"));
const OrderPage = lazy(() => import("./page/OrderPage"));
const SelectAddressPage = lazy(() => import("./page/SelectAddressPage"));
const OrderDetailsPage = lazy(() => import("./page/OrderDetailsPage"));
const ForgotPasswordPage = lazy(() => import("./page/ForgotPasswordPage"));
const ResetPasswordPage = lazy(() => import("./page/ResetPasswordPage"));
const ProfilePage = lazy(() => import("./page/ProfilePage"));
const SubCategoryPage = lazy(() => import("./page/SubCategoryPage"));
const AdminDashboardPage = lazy(() => import("./page/AdminDashboardPage"));
const AboutUsPage = lazy(() => import("./page/AboutUsPage"));
const ProductPageMobile = lazy(() => import("./page/mobile/ProductPageMobile"));
const UserVerificationPage = lazy(() => import("./page/UserVerificationPage"));
const PaymentSuccessPage = lazy(() => import("./page/PaymentSuccessPage"));
const UploadProduct = lazy(() => import("./page/UploadProduct"));
const DesignerPage = lazy(() => import("./page/DesignerPage"));
const PageNotFound = lazy(() => import("./page/PageNotFound"));
const Navbar = lazy(() => import("./components/Navbar"));
const Footer = lazy(() => import("./components/Footer"));
// const Loading = lazy(() => import("./components/Loading"));
const CustomerSupportPage = lazy(() => import("./page/CustomerSupportPage"));
const CreateCouponsPage = lazy(() => import("./page/CreateCouponsPage"));
const CreateBadgePage = lazy(() => import("./page/CreateBadgePage"));
const HandleGoogleLoginPage = lazy(() => import("./page/HandleGoogleLogin"));
const PrivacyPolicyPage = lazy(() => import("./page/PrivacyPolicy"));
const SearchPage = lazy(() => import("./page/SearchPage"));
const WishlistPage = lazy(() => import("./page/WishlistPage"));
const CategoryPage = lazy(() => import("./page/CategoryPage"));

function App() {
  const [isMobile, setIsMobile] = useState(false);
  useEffect(() => {
    const handleResize = () => {
      setIsMobile(window.innerWidth <= 650);
    };

    // Add event listener for window resize
    window.addEventListener("resize", handleResize);

    // Initial check for mobile on component mount
    handleResize();

    // Clean up event listener on unmount
    return () => window.removeEventListener("resize", handleResize);
  }, []);

  return (
    <>
      <Helmet>
        <title>Vedhasa | Traditional Ethnic Clothing & Accessories</title>
        <meta
          name="description"
          content="Explore a vast collection of ethnic clothing and accessories for women."
        />
        <link rel="icon" href="/favicon.ico" type="image/x-icon" />
        <link rel="icon" href="/favicon.png" type="image/png" />
      </Helmet>
      <Router>
        <Navbar />
        {/* <Temporary /> */}
        <Suspense fallback={<LoadingSpinner />}>
          <main>
            <Routes>
              <Route index element={<HomePage />} />
              <Route
                path="/subcategory/:subCategoryId/:subCategoryName"
                element={<SubCategoryPage />}
              />
              <Route
                path="/category/:categoryId/:categoryName"
                element={<CategoryPage />}
              />
              <Route path="/auth" element={<AuthenticationPage />} />
              <Route
                path="/handle-google-login"
                element={<HandleGoogleLoginPage />}
              />
              <Route
                path="/verification/:email"
                element={<UserVerificationPage />}
              />
              <Route
                path="/product/:id"
                element={isMobile ? <ProductPageMobile /> : <ProductPage />}
              />
              <Route path="/cart" element={<CartPage />} />
              <Route path="/address" element={<AddressPage />} />
              <Route path="/select-address" element={<SelectAddressPage />} />
              <Route path="/checkout" element={<CheckoutPage />} />
              <Route path="/order" element={<OrderPage />} />
              <Route
                path="/order-details/:orderId/:productId"
                element={<OrderDetailsPage />}
              />
              <Route path="/forgot-password" element={<ForgotPasswordPage />} />
              <Route path="/reset-password" element={<ResetPasswordPage />} />
              <Route path="/profile" element={<ProfilePage />} />
              <Route path="/dashboard" element={<AdminDashboardPage />} />
              <Route path="/about-us" element={<AboutUsPage />} />
              <Route path="/payment-success" element={<PaymentSuccessPage />} />
              <Route path="/designer" element={<DesignerPage />} />
              <Route
                path="/upload-product/:productId"
                element={<UploadProduct />}
              />
              <Route path="/upload-product" element={<UploadProduct />} />
              <Route
                path="/customer-support"
                element={<CustomerSupportPage />}
              />
              <Route path="/create-coupons" element={<CreateCouponsPage />} />
              <Route path="/create-badge" element={<CreateBadgePage />} />
              <Route path="/privacy-policy" element={<PrivacyPolicyPage />} />
              <Route path="/search-page" element={<SearchPage />} />
              <Route path="/manage-categories" element={<ManageCategories />} />
              <Route path="/wishlist" element={<WishlistPage />} />
              <Route path="*" element={<PageNotFound />} />
            </Routes>
          </main>
        </Suspense>
        <Footer />
      </Router>
    </>
  );
}

export default App;
