import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";

import BASE_URL from "../../utils/baseUrl";
import axios from "axios";
import axiosInstance from "../../utils/axiosInstance";
import { setItemToLocalStorage } from "../../utils/localStorage";

export const changeStock = createAsyncThunk("changeStock", async (items) => {
  try {
    const axiosInstance = axios.create({
      headers: {
        Authorization: `Bearer ${JSON.parse(localStorage.getItem("token"))}`,
      },
    });
    await axiosInstance.patch(`${BASE_URL}/product/change-stock`, {
      items,
    });
  } catch (error) {
    console.log(error);
  }
});

export const addItemsToCart = createAsyncThunk(
  "addItemsToCart",
  async ({ productId, size }, { dispatch, rejectWithValue }) => {
    try {
      await axiosInstance.post(`${BASE_URL}/cart`, {
        item: { productId, size, quantity: 1 },
      });
      dispatch(fetchCartData());
    } catch (error) {
      return rejectWithValue(error.response.data.message);
    }
  }
);

export const fetchCartData = createAsyncThunk("fetchCartData", async () => {
  try {
    const { data } = await axiosInstance.get(`${BASE_URL}/cart`);
    return data;
  } catch (error) {
    console.log(error);
  }
});

export const decreaseCartItemQty = createAsyncThunk(
  "decreaseCartItemQty",
  async ({ productId, size }, { dispatch }) => {
    try {
      await axiosInstance.delete(
        `${BASE_URL}/cart/decrease?productId=${productId}&size=${size}`
      );
      dispatch(fetchCartData());
    } catch (error) {
      console.log(error);
    }
  }
);

export const emptyCart = createAsyncThunk(
  "emptyCart",
  async (_, { dispatch }) => {
    try {
      const { data } = await axiosInstance.get(`${BASE_URL}/cart/clear`);
      dispatch(fetchCartData());
      return data;
    } catch (error) {
      console.log(error);
    }
  }
);

const cartSlice = createSlice({
  name: "cart",
  initialState: {
    data: localStorage.getItem("cart_new")
      ? JSON.parse(localStorage.getItem("cart_new"))
      : {},
    isLoading: false,
    isError: false,
    errorMessage: null,
    isProductAdded: false,
  },
  reducers: {
    resetIsProductAdded: (state) => {
      state.isProductAdded = false;
    },
    changeSizeAction: (state, action) => {
      const { id, size } = action.payload;
      const itemToUpdate = state.data.find((item) => item._id === id);
      if (itemToUpdate) {
        itemToUpdate.size = size;
        localStorage.setItem("cart", JSON.stringify(state.data));
      }
    },
    changeQuantityAction: (state, action) => {
      const { id, qty } = action.payload;
      const itemToUpdate = state.data.find((item) => item._id === id);
      if (itemToUpdate) {
        itemToUpdate.qty = qty;
        localStorage.setItem("cart", JSON.stringify(state.data));
      }
    },
    resetError: (state) => {
      state.isError = false;
      state.errorMessage = null;
    },
  },
  extraReducers: (builder) => {
    builder.addCase(addItemsToCart.pending, (state) => {
      state.isLoading = true;
      state.isProductAdded = false;
    });
    builder.addCase(addItemsToCart.fulfilled, (state) => {
      state.isProductAdded = true;
    });
    builder.addCase(addItemsToCart.rejected, (state, action) => {
      state.isLoading = false;
      state.isError = true;
      state.errorMessage = action.payload;
    });

    builder.addCase(decreaseCartItemQty.pending, (state) => {
      state.isLoading = true;
      state.isProductAdded = false;
    });

    builder.addCase(fetchCartData.pending, (state) => {
      state.isLoading = true;
      state.isProductAdded = false;
      state.isError = false;
    });
    builder.addCase(fetchCartData.fulfilled, (state, action) => {
      setItemToLocalStorage({ key: "cart_new", value: action.payload });
      state.data = action.payload;
      state.isLoading = false;
      state.isError = false;
    });
    builder.addCase(fetchCartData.rejected, (state) => {
      state.isLoading = false;
      state.isError = true;
    });
    builder.addCase(emptyCart.pending, (state) => {
      state.isLoading = true;
    });
    builder.addCase(emptyCart.fulfilled, (state) => {
      state.isLoading = false;
      localStorage.removeItem("cart_new");
    });
  },
});

export const {
  removeFromCart,
  changeSizeAction,
  changeQuantityAction,
  resetError,
  resetIsProductAdded,
} = cartSlice.actions;

export default cartSlice.reducer;
